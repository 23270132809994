<template>
  <!-- loading page -->
  <div>
    <PlaceOrder
      v-if="paymentDialog"
      :is-dialog-open.sync="paymentDialog"
      :selected-package="selectedPackage"
    />
    <CheckBeforePay
      v-else-if="selectedPackage"
      :is-dialog-open-before-pay.sync="userConfirmBeforePay"
      :selected-package="selectedPackage"
      :timing-dialog.sync="timingDialog"
      :whats-app-dialog.sync="whatsAppDialog"
      :event-details-dialog.sync="eventDetailsDialog"
      :is-upgrade="isUpgrade"
      :web-view-prevent-payment="webViewPreventPayment"
      @validationIsOk="validationIsOk"
      @closeAllDialogs="closeAllDialogs"
    />

    <div
      v-if="loading"
      class="d-flex justify-center"
    >
      <v-progress-circular
        :size="50"
        indeterminate
        color="primary"
      >
      </v-progress-circular>
    </div>
    <div
      v-else
      class="full-page"
    >
      <v-card
        v-if="packages"
        color="transparent"
        flat
        class="text-center align-center justify-center rounded-lg"
        style="height: 100%"
      >
        <v-card-title
          class="pa-1"
          dir="ltr"
        >
          <v-btn
            icon
            @click="moveToDashboard"
          >
            <v-icon>{{ icons.mdiClose }}</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="mt-0 pt-0">
          <v-row>
            <v-col
              cols="12"
              md="12"
              sm="12"
              class="mx-auto pt-0"
            >
              <h1 class="font-weight-bold mb-2">
                <v-icon
                  color="primary"
                  :size="$vuetify.breakpoint.mdAndDown ? '27' : '35'"
                >
                  {{ icons.mdiPartyPopper }}
                </v-icon>
                <span
                  :class="$vuetify.breakpoint.mdAndDown ? 'text-2xl': 'text-4xl'"
                >
                  {{ $t('OurPackages') }}
                </span>
                <v-icon
                  color="primary"
                  class="me-2"
                  :size="$vuetify.breakpoint.mdAndDown ? '27' : '35'"
                >
                  {{ icons.mdiPartyPopper }}
                </v-icon>
              </h1>
              <!-- subtitle -->
              <div
                class="font-weight-semibold px-2"
                :class="$vuetify.breakpoint.mdAndDown ? 'text-base': 'text-h6'"
              >
                {{ $t('GuideMessageSelectPackage') }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="mt-3 pb-15">
          <v-row
            justify="center"
            align="center"
          >
            <v-col
              offset-sm="2"
              sm="10"
              md="12"
              offset-lg="2"
              lg="10"
              xl="8"
              class="mx-auto"
            >
              <v-row
                justify="center"
                align="center"
              >
                <!-- available card -->
                <v-col
                  v-for="packageCard in packages.availablePackages"
                  :key="packageCard.id"
                  cols="12"
                  md="5"
                  sm="12"
                  lg="4"
                  class="text-center justify-center"
                >
                  <v-card
                    style="background-color: rgba(255, 255, 255, 0.8);border: solid;"
                    flat
                    width="370px"
                    class="text-center d-flex flex-column justify-center rounded-xl"
                    :style="{
                      borderColor: packages.orderPackageId === packageCard.id ? 'rgba(145, 85, 253, 0.8)' : '',
                    }"
                  >
                    <v-card-text>
                      <!-- Active Card -->
                      <v-fab-transition
                        v-if="packages.orderPackageId === packageCard.id"
                      >
                        <v-btn
                          color="primary"
                          dark
                          absolute
                          top
                          left
                          fab
                          x-small
                        >
                          <v-icon>
                            {{ icons.mdiCheckBold }}
                          </v-icon>
                        </v-btn>
                      </v-fab-transition>
                      <v-row
                        justify="center"
                        align="center"
                        class="mb-1 d-flex pt-3"
                      >
                        <!-- Premium card -->
                        <v-chip
                          v-if="packageCard.callAllowed"
                          class="text-h6 font-weight-semibold primary--text"
                        >
                          <v-icon
                            left
                            color="primary"
                          >
                            {{ icons.mdiStarCircle }}
                          </v-icon>
                          {{ $t(packageCard.name) }}
                        </v-chip>

                        <!-- Basic card -->
                        <v-chip
                          v-if="!packageCard.callAllowed"
                          class="text-h6 font-weight-semibold primary--text"
                        >
                          {{ $t(packageCard.name) }}
                        </v-chip>
                      </v-row>

                      <p
                        class="align-center justify-center"
                      >
                        {{ $t(packageCard.description) }}
                      </p>

                      <div class="d-flex align-center justify-center mb-n3 mt-n3">
                        <v-radio-group
                          v-model="packageCard.selectedRadioGroup"
                          class="d-flex justify-center"
                          row
                          @change="changedSelectedGuestAmount(packageCard)"
                        >
                          <!-- whatsApp radio option -->
                          <v-radio
                            :value="3"
                            color="success"
                          >
                            <template v-slot:label>
                              <v-card
                                width="30"
                                color="transparent"
                                flat
                                class="me-5"
                              >
                                <v-img
                                  contain
                                  height="50"
                                  :src="require('@/assets/images/messages/whatsApp.png')"
                                ></v-img>
                              </v-card>
                            </template>
                          </v-radio>
                          <!-- SMS radio option -->
                          <v-radio
                            :value="1"
                            color="indigo"
                          >
                            <template v-slot:label>
                              <v-card
                                width="30"
                                color="transparent"
                                flat
                              >
                                <v-img
                                  contain
                                  height="50"
                                  :src="require('@/assets/images/messages/sms.png')"
                                ></v-img>
                              </v-card>
                            </template>
                          </v-radio>
                        </v-radio-group>
                      </div>
                      <!-- Chosen guest amount -->
                      <div>
                        <div class="d-flex align-center justify-center">
                          {{ $t('ChooseGuestAmount') }}
                        </div>
                        <v-row
                          justify="center"
                          align="center"
                          class="pt-2"
                        >
                          <v-col
                            cols="5"
                          >
                            <v-select
                              v-model="packageCard.selectedGuestAmount"
                              :items="packageCard.prices.map(el => el.rows)"
                              dense
                              :no-data-text="$t('NoData')"
                              hide-details
                              @change="changedSelectedGuestAmount(packageCard)"
                            >
                              <template v-slot:selection="{ item }">
                                <span
                                  class="d-flex justify-center align-center text-xl"
                                  style="width: 100%;"
                                >
                                  {{ item }}
                                </span>
                              </template>
                            </v-select>
                          </v-col>
                        </v-row>
                      </div>
                    </v-card-text>
                    <v-card-text
                      :class="$vuetify.breakpoint.mdAndUp ? 'pa-3':''"
                    >
                      <v-col
                        :style="$vuetify.breakpoint.mdAndUp ? '':'padding:0px; margin:0px'"
                      >
                        <!-- list for packages of includes and not includes -->
                        <v-list
                          class="pb-2 align-center"
                          style="text-align: justify;"
                          dense
                          color="transparent"
                        >
                          <!-- include items -->
                          <v-list-item
                            v-for="(item, index) in $t(`${packageCard.name}_include_${packageCard.selectedRadioGroup === 1 ? 'sms' : 'whatsapp'}`)"
                            :key="index"
                            class="mt-n2"
                            :style="$vuetify.breakpoint.mdAndUp ? '':'padding:0px'"
                          >
                            <v-list-item-icon class="align-center">
                              <v-icon
                                size="23"
                                color="primary"
                              >
                                {{ icons.mdiCheckCircle }}
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content
                              class="text-start text-base"
                            >
                              {{ item }}
                            </v-list-item-content>
                          </v-list-item>
                          <!-- not include items -->
                          <v-list-item
                            v-for="(item) in $t(`${packageCard.name}_not_include`)"
                            :key="item"
                            class="mt-n2"
                            :style="$vuetify.breakpoint.mdAndUp ? '':'padding:0px'"
                          >
                            <v-list-item-icon class="align-center">
                              <v-icon
                                size="23"
                                color="secondary"
                              >
                                {{ icons.mdiCloseCircle }}
                              </v-icon>
                            </v-list-item-icon>
                            <v-list-item-content
                              class="text-start text-base"
                            >
                              {{ item }}
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </v-col>

                      <!-- Payment details for previous orders-->
                      <v-col
                        class="my-0 py-0"
                        :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
                      >
                        <v-list v-if="Number(packages.packageAmount) > 0">
                          <div
                            style="text-align: start;"
                          >
                            <v-list-item :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''">
                              <span class="text-lg">
                                {{ $t('package_price') }}
                              </span>
                              <span class="text-xl primary--text mx-2">
                                {{ packageCard.prices.filter(el => el.rows === packageCard.selectedGuestAmount).length > 0 ? (packageCard.selectedRadioGroup === 1 ? packageCard.prices.filter(el => el.rows === packageCard.selectedGuestAmount)[0].priceWithSms : packageCard.prices.filter(el => el.rows === packageCard.selectedGuestAmount)[0].priceWithWhatsapp) : '0' }}
                              </span>
                              <span
                                class="text-lg primary--text mx-n2"
                              >₪</span>
                            </v-list-item>
                            <v-list-item
                              class="mt-n4 pt-0"
                              :class="$vuetify.breakpoint.smAndDown ? 'px-0' : ''"
                            >
                              <span class="text-lg">
                                {{ $t('ExistingPackageAmount') }}
                              </span>
                              <span class="text-xl primary--text mx-2">{{ packages.packageAmount }} </span>
                              <span
                                class="text-lg primary--text mx-n2"
                              >₪</span>
                            </v-list-item>
                          </div>
                        </v-list>
                      </v-col>
                      <!-- display price of package -->
                      <v-row
                        justify="center"
                        class="mt-5"
                        style="direction: rtl"
                      >
                        <div>
                          <!-- new price -->
                          <span
                            class="text-5xl primary--text font-weight-semibold"
                          >
                            {{ packageCard.priceToPay < 0 ? 0 : packageCard.priceToPay }}
                          </span>
                          <span
                            class="text-4xl primary--text mr-n3"
                          >₪</span>
                        </div>
                      </v-row>

                      <!-- pay button -->
                      <v-btn
                        :disabled="packageCard.priceToPay <= 0"
                        block
                        color="primary"
                        class="mt-5 rounded-lg"
                        @click="buyPackage(packageCard)"
                      >
                        {{ Number(packages.packageAmount) > 0 ? $t('UpgradePackage'): $t('BuyPackage') }}
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import { i18n } from '@/plugins/i18n/index'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import { useRouter } from '@core/utils'
// eslint-disable-next-line object-curly-newline
import usePopupWithHash from '@/composables/usePopupWithHash'
import useWorkingDays from '@/composables/useWorkingDays'
import {
  mdiCheckBold, mdiCheckCircle, mdiClose, mdiCloseCircle, mdiPartyPopper, mdiStarCircle,
} from '@mdi/js'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import Vue from 'vue'
import { useToast } from 'vue-toastification/composition'
import CheckBeforePay from './CheckBeforePay.vue'
import PlaceOrder from './PlaceOrder.vue'

export default {
  components: {
    PlaceOrder,
    CheckBeforePay,
  },
  setup() {
    const packages = ref()
    const price = ref(0)
    const { route, router } = useRouter()
    const loading = ref(true)
    const toast = useToast()
    const showToast = msg => toast.error(i18n.t(msg))

    const userConfirmBeforePay = ref(false)
    const selectedPackage = ref(null)
    const { isPopupOpen: timingDialog } = usePopupWithHash('timing-confirm')
    const { isPopupOpen: eventDetailsDialog } = usePopupWithHash('event-details-confirm')
    const { isPopupOpen: whatsAppDialog } = usePopupWithHash('whatsapp-confirm')
    const { isPopupOpen: paymentDialog } = usePopupWithHash('payment')
    const { isPopupOpen: webViewPreventPayment } = usePopupWithHash('prevent-payment')

    const moveToDashboard = () => {
      router.push({ name: 'home-page', params: { id: route.value.params.id } })
    }

    const changedSelectedGuestAmount = id => {
      loading.value = true
      packages.value.availablePackages.find(el => el.name === id.name).priceToPay = price.value
      if (id.selectedGuestAmount) {
        // eslint-disable-next-line operator-linebreak
        packages.value.availablePackages.find(el => el.name === id.name).priceToPay =
          (id.selectedRadioGroup === 3
            ? id.prices.filter(el => el.rows === id.selectedGuestAmount)[0].priceWithWhatsapp
            : id.prices.filter(el => el.rows === id.selectedGuestAmount)[0].priceWithSms)
          - Number(packages.value.packageAmount)
      }
      loading.value = false
    }

    const packageInfo = () => {
      loading.value = true
      store
        .dispatch('getPackages', { id: route.value.params.id })
        .then(res => {
          packages.value = {
            ...res.data,
            availablePackages: res.data.availablePackages.map(el => {
              // Default type of messages is SMS
              const selectedRadioGroup = res.data.orderMessageSystem ?? 1

              return {
                ...el,
                selectedRadioGroup,
                priceToPay:
                  selectedRadioGroup === 1
                    ? el.prices[0].priceWithSms - res.data.packageAmount
                    : el.prices[0].priceWithWhatsapp - res.data.packageAmount,
                selectedGuestAmount: el.prices[0].rows,
              }
            }),
          }
        })
        .catch(() => showToast('errorManageEvents'))
        .finally(() => {
          loading.value = false
        })
    }
    packageInfo()
    const isUpgrade = ref(false)

    const closeAllDialogs = () => {
      userConfirmBeforePay.value = false
      timingDialog.value = false
      whatsAppDialog.value = false
      eventDetailsDialog.value = false
      webViewPreventPayment.value = false
    }

    const buyPackage = details => {
      closeAllDialogs()

      const now = new Date()
      const minWorkingDate = useWorkingDays(now, 5)

      selectedPackage.value = details

      Vue.prototype.$gtag.event('Selected_Package', {
        event_category: 'Package Selection',
        event_label: `Package: ${details.name || details.id}`,
      })

      if (store.getters['app/getIsInWebView']) {
        webViewPreventPayment.value = true

        return
      }

      // If the user wants to upgrade the package, do not display the confirmation checkbox
      if (packages.value.orderPackageId === null) {
        // Check if the event date is today or in the past
        if (moment(store.state.eventData.date).startOf('day').isSameOrAfter(moment().startOf('day'))) {
          // check if have 5 working days until the event date
          if (moment(minWorkingDate).startOf('day').isAfter(moment(store.state.eventData.date).startOf('day'))) {
            showToast('bad_date')

            return
          }

          userConfirmBeforePay.value = true
          timingDialog.value = true
        } else {
          showToast('eventDateInPast')
        }
      } else if (moment(store.state.eventData.date).startOf('day').isBefore(moment().startOf('day'))) {
        showToast('eventDateInPast')
      } else {
        isUpgrade.value = true
        userConfirmBeforePay.value = true
        timingDialog.value = true
      }
    }

    const validationIsOk = item => {
      if (item) {
        paymentDialog.value = true
        closeAllDialogs()
      }
    }

    return {
      buyPackage,
      changedSelectedGuestAmount,
      paymentDialog,
      userConfirmBeforePay,
      selectedPackage,
      price,
      packages,
      loading,
      validationIsOk,
      moment,
      timingDialog,
      isUpgrade,
      whatsAppDialog,
      eventDetailsDialog,
      closeAllDialogs,
      moveToDashboard,
      webViewPreventPayment,

      icons: {
        mdiPartyPopper,
        mdiCheckCircle,
        mdiCloseCircle,
        mdiStarCircle,
        mdiClose,
        mdiCheckBold,
      },
    }
  },
}
</script>
<style scoped>
::v-deep.full-page {
  height: 100%;
  background-color: transparent;
}

@media (max-width: 768px) {
  .full-page {
    height: auto;
    min-height: 100vh;
  }
}
.v-input--radio-group.v-input--radio-group--row .v-radio {
  margin-right: 0px;
}
::v-deep .v-input--selection-controls__input {
  margin-left: 1px !important;
  margin-right: 2px !important;
}
</style>
