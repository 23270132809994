<template>
  <v-dialog
    v-model="dialogOpen"
    fullscreen
    hide-overlay
  >
    <v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="dialogOpen = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-actions>
      <v-container
        fluid
        class="payment-wrapper"
      >
        <v-row>
          <v-col cols="12">
            <div
              class="mx-auto"
              style="max-width: 800px"
            >
              <v-card-text class="card-main-content">
                <v-stepper
                  v-model="step"
                  class="pa-0"
                  outlined
                  shaped
                >
                  <v-stepper-header>
                    <v-stepper-step
                      :complete="step > 1"
                      step="1"
                    >
                      {{ $t('Order') }}
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step
                      :complete="step > 2"
                      step="2"
                    >
                      {{ $t('payment') }}
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="3">
                      {{ $t('Confirmation') }}
                    </v-stepper-step>
                  </v-stepper-header>
                  <v-divider></v-divider>
                  <v-stepper-items
                    style="margin-top:30px; margin-bottom:30px"
                  >
                    <v-stepper-content step="1">
                      <div
                        class="mx-auto"
                        style="max-width: 400px"
                      >
                        <div class="text-h5 text-center">
                          {{ $t('How_pay') }}
                        </div>

                        <!-- Payment method cards start here -->
                        <v-row
                          justify="center"
                          align="center"
                          class="mt-7 mb-8"
                        >
                          <v-col
                            v-for="(method, index) in paymentMethods"
                            :key="index"
                            cols="10"
                            lg="5"
                            md="5"
                            sm="5"
                            class="pa-2"
                          >
                            <v-card
                              v-ripple="false"
                              outlined
                              class="text-center payment-card"
                              :class="{ 'payment-card-selected': selectedPaymentMethod === method.name }"
                              @click="selectPaymentMethod(method.name)"
                            >
                              <v-card-text>
                                <component
                                  :is="method.icon"
                                  v-if="typeof method.icon === 'object'"
                                  class="custom-payment-method-logo"
                                  color="primary"
                                ></component>
                                <v-icon
                                  v-else
                                  large
                                  color="primary"
                                >
                                  {{ method.icon }}
                                </v-icon>
                                <div class="text-subtitle-2 header-color font-weight-medium mt-1">
                                  {{ $t(method.title) }}
                                </div>
                              </v-card-text>
                            </v-card>
                          </v-col>
                        </v-row>
                        <!-- Payment method cards end here -->

                        <v-card
                          outlined
                          :loading="loading"
                        >
                          <div
                            class="mx-auto"
                            style="padding: 30px; padding-top: 50px; padding-bottom: 20px"
                          >
                            <v-row class="d-flex justify-center promo-wrapper mx-auto">
                              <v-text-field
                                v-model="selectedCouponName"
                                dense
                                outlined
                                class="mt-1 custom-field"
                                :placeholder="$t('EnterPromoCode')"
                                :disabled="Boolean(selectedCouponDiscount)"
                              ></v-text-field>
                              <v-btn
                                dense
                                outlined
                                class="mt-1 ml-2 mr-2 custom-btn"
                                :loading="loading"
                                :disabled="Boolean(selectedCouponDiscount)"
                                @click="verifyCoupon"
                              >
                                {{ $t('Apply') }}
                              </v-btn>
                            </v-row>
                          </div>

                          <v-card-text>
                            <div class="text-subtitle-1 mb-4 header-color font-weight-medium">
                              {{ $t('OrderDetails') }}
                            </div>
                            <div class="text-subtitle-2 d-flex justify-space-between mb-2">
                              <span>{{ $t(selectedPackage.name) }} - {{ selectedPackage.selectedGuestAmount }}
                                {{ $t('Invitees') }}</span><span>₪{{ selectedPackage.priceToPay }}</span>
                            </div>
                            <div class="text-subtitle-2 d-flex mb-2">
                              <span>{{ $t(selectedPackage.description) }}</span>
                              <span
                                :class="$vuetify.rtl ? 'mr-3' : 'ml-3'"
                              ><v-icon
                                size="22"
                                :color="findColor(selectedPackage.selectedRadioGroup)"
                              >
                                {{ findIcon(selectedPackage.selectedRadioGroup) }}
                              </v-icon></span>
                              <span
                                v-if="selectedPackage.callAllowed"
                                :class="$vuetify.rtl ? 'mr-3' : 'ml-3'"
                              ><v-icon
                                size="22"
                                :color="findColor(messagesType.CALL)"
                              >
                                {{ findIcon(messagesType.CALL) }}
                              </v-icon></span>
                            </div>

                            <div
                              v-if="selectedCouponDiscount"
                              class="text-subtitle-2 d-flex justify-space-between mb-2"
                            >
                              <span>{{ $t('CouponDiscount') }}({{ selectedCouponDiscount }}%)</span>₪-{{
                                selectedPackage.priceToPay - totalPrice
                              }}
                            </div>
                          </v-card-text>

                          <v-divider></v-divider>

                          <!-- Total Price -->
                          <v-card-text class="d-flex justify-space-between py-4">
                            <div class="text-subtitle-1 header-color font-weight-medium">
                              {{ $t('TotalForPay') }}
                            </div>
                            <div class="text-subtitle-1 header-color font-weight-medium">
                              ₪{{ totalPrice }}
                            </div>
                          </v-card-text>
                        </v-card>
                        <v-btn
                          color="primary"
                          block
                          large
                          class="mt-7"
                          :loading="loading"
                          @click="initPayment"
                        >
                          {{ $t('Payment') }}
                        </v-btn>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      <div
                        class="mx-auto"
                        style="max-width: 500px;"
                      >
                        <v-card
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowfullscreen
                          :loading="loading"
                        >
                          <v-card-text class="px-4 py-4">
                            <iframe
                              id="payment-iframe"
                              :src="iframeUrl"
                              scrolling="no"
                              frameborder="0"
                              style="width: 100% ; height: 900px; border: none"
                            >
                            </iframe>
                          </v-card-text>
                          <v-btn
                            color="primary"
                            small
                            class="mt-0 ma-4"
                            :loading="loading"
                            @click="stepBack"
                          >
                            {{ $t('Previous') }}
                          </v-btn>
                        </v-card>
                      </div>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                      <v-container
                        fluid
                        style="text-align: center;"
                      >
                        <!-- Centered confirmation icon -->
                        <v-row justify="center">
                          <v-icon
                            size="60"
                            color="primary"
                          >
                            mdi-checkbox-marked-circle-outline
                          </v-icon>
                        </v-row>

                        <!-- Confirmation text -->
                        <v-row
                          justify="center"
                          class="text-h5 mt-6 font-weight-medium"
                        >
                          {{ $t('Payment_completed_Successfully') }}
                        </v-row>

                        <!-- Order confirmation number + explanation -->
                        <v-row
                          justify="center"
                          class="text-subtitle-1 mt-4"
                        >
                          <div>
                            {{ $t('Thank_you_for_your_purchase') }}  <strong>#{{ confirmationNumber }}</strong>
                          </div>
                        </v-row>

                        <!-- Button to go to the dashboard -->
                        <v-row
                          justify="center"
                          class="mt-8"
                        >
                          <v-btn
                            color="primary"
                            large
                            @click="moveToDashboard"
                          >
                            {{ $t('Go_To_Event_Page') }}
                          </v-btn>
                        </v-row>
                      </v-container>
                    </v-stepper-content>
                  </v-stepper-items>
                </v-stepper>
              </v-card-text>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>
<script>
import useMessagesType from '@/composables/useMessagesType'
import usePaymentMethods from '@/composables/usePaymentMethods'
import { i18n } from '@/plugins/i18n/index'
import router from '@/router'
import axios from '@axios'
import { useRouter } from '@core/utils'
// eslint-disable-next-line object-curly-newline
import { computed, onMounted, onUnmounted, ref } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import Vue from 'vue'

export default {
  name: 'PlaceOrder',
  props: {
    isDialogOpen: {
      type: Boolean,
      default: false,
    },
    selectedPackage: {
      type: Object,
      default: () => ({
        selectedGuestAmount: 50,
        selectedRadioGroup: 1,
        name: 'premium_package',
        description: 'premium_desc',
        callAllowed: true,
        priceToPay: 100,
      }),
    },
  },
  setup(props, { emit }) {
    const { route } = useRouter()
    const toast = useToast()

    const loading = ref(false)
    const selectedCouponName = ref('')
    const selectedCouponDiscount = ref(null)
    const confirmationNumber = ref(null)
    const iframeUrl = ref(null)
    const step = ref(1)
    const totalPrice = ref(props.selectedPackage.priceToPay)
    const selectedPaymentMethod = ref(null)

    const dialogOpen = computed({
      get: () => props.isDialogOpen,
      set: value => emit('update:isDialogOpen', value),
    })

    const { messagesTypeMapping, messagesType } = useMessagesType()
    const { paymentMethodsOptions } = usePaymentMethods()
    const paymentMethods = ref(paymentMethodsOptions)

    selectedPaymentMethod.value = paymentMethods.value[0].name

    const findIcon = type => messagesTypeMapping.find(mapping => mapping.value === type).icon
    const findColor = type => messagesTypeMapping.find(mapping => mapping.value === type).color

    const moveToDashboard = () => {
      router.push({ name: 'home-page', params: { id: route.value.params.id } })
    }

    const moveToNextStep = () => {
      if (step.value < 2 || step.value === 1) {
        step.value += 1
        loading.value = false
      }
    }

    const stepBack = () => {
      if (step.value > 1) {
        step.value -= 1
        iframeUrl.value = null
      }
    }

    const receiveMessage = event => {
      if (event?.data?.message === 'paymentreplay') {
        if (event.data?.value.OrderStatus === 3) {
          axios
            .post(`payment/validateTransaction/${route.value.params.id}/${event.data.value.uniqId}`, {})
            .then(res => {
              step.value += 1
              confirmationNumber.value = res.data.confirmationId
            })
            .catch(() => {
              toast.error(i18n.t('payment_failed'))
            })
        } else {
          toast.error(i18n.t('payment_failed'))
          initPayment()
        }
      }
    }

    onMounted(() => {
      window.addEventListener('message', receiveMessage, false)
    })

    onUnmounted(() => {
      window.removeEventListener('message', receiveMessage)
    })

    const selectPaymentMethod = method => {
      selectedPaymentMethod.value = method
    }

    const verifyCoupon = () => {
      if (!selectedCouponName.value) {
        return
      }

      loading.value = true

      axios
        .get(`coupon/${selectedCouponName.value}`)
        .then(res => {
          selectedCouponDiscount.value = res.data.discount
          totalPrice.value =
            props.selectedPackage.priceToPay - props.selectedPackage.priceToPay * (res.data.discount / 100)
        })
        .catch(err => {
          toast.error(i18n.t(err.response.data.message))
        })
        .finally(() => {
          loading.value = false
        })
    }

    const initPayment = () => {
      loading.value = true

      axios
        .post(`placeOrder/${route.value.params.id}`, {
          packageName: props.selectedPackage.name,
          messageSystem: props.selectedPackage.selectedRadioGroup,
          rows: props.selectedPackage.selectedGuestAmount,
          couponCode: selectedCouponName.value,
          paymentMethod: selectedPaymentMethod.value,
        })
        .then(res => {
          Vue.prototype.$gtag.event('Payment', {
            event_category: 'Payment',
            event_label: 'Payment success',
          })

          iframeUrl.value = res.data.link
          moveToNextStep()
        })
        .catch(err => {
          toast.error(i18n.t(err.response.data.message))
        })
        .finally(() => {
          loading.value = false
        })
    }

    return {
      step,
      dialogOpen,
      moveToDashboard,
      initPayment,
      moveToNextStep,
      confirmationNumber,
      iframeUrl,
      stepBack,
      loading,
      selectedCouponName,
      selectedCouponDiscount,
      verifyCoupon,
      findIcon,
      findColor,
      messagesType,
      totalPrice,
      paymentMethods,
      selectPaymentMethod,
      selectedPaymentMethod,
    }
  },
}
</script>

<style scoped>
.header-color {
  color: #534f59;
}
.payment-card {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  transform-origin: center center;
}

.v-card--link:focus::before {
  opacity: 0 !important;
}
.payment-card-selected {
  background-color: #f5f5f58a; /* Selected state background */
  transform: scale(1.1);
}

@media (max-width: 600px) {
  .v-stepper__content {
    padding: 0 !important;
  }
  .card-main-content {
    padding: 0 !important;
  }
}

@media (max-width: 431px) {
  .promo-wrapper {
    width: 95%;
  }
  .custom-field {
    margin-bottom: 0px !important;
  }
  .custom-btn {
    margin-top: -10px !important;
    margin-bottom: 10px;
  }
}

.payment-wrapper {
  margin-top: -40px;
}

@media (max-width: 1000px) {
  .payment-wrapper {
    margin-top: 0;
  }
}

.custom-payment-method-logo {
  width: 50px;
}
</style>
