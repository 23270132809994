<template>
  <svg width="62" height="33" viewBox="0 0 62 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_9_2)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M30.332 8.60409C31.556 8.60409 32.548 9.59309 32.548 10.8121V28.1481C32.548 29.3681 31.556 30.3561 30.332 30.3561C30.0415 30.3566 29.7538 30.2999 29.4852 30.1892C29.2167 30.0786 28.9725 29.9161 28.7667 29.711C28.561 29.506 28.3976 29.2625 28.2859 28.9943C28.1743 28.7261 28.1165 28.4386 28.116 28.1481V10.8121C28.116 9.59209 29.108 8.60409 30.332 8.60409ZM7.25402 -0.00390625C8.48502 -0.00390625 9.48302 0.982094 9.48302 2.19909V8.60209H16.005C22.059 8.60209 26.985 13.4701 26.985 19.4521C26.985 25.4351 22.059 30.3021 16.005 30.3021C9.95002 30.3021 5.02502 25.4351 5.02502 19.4521V13.0081H2.75502C1.52302 13.0081 0.525024 12.0221 0.525024 10.8051C0.525024 9.58909 1.52302 8.60209 2.75502 8.60209H5.02502V2.20009C5.02502 0.983094 6.02302 -0.00290625 7.25402 -0.00290625V-0.00390625ZM38.998 -0.00390625C40.235 -0.00390625 41.238 0.982094 41.238 2.19909V8.60209H46.852C48.089 8.60209 49.092 9.58909 49.092 10.8051C49.092 12.0221 48.089 13.0081 46.852 13.0081H41.238V19.4521C41.238 22.9751 44.042 25.8051 47.621 25.8951C48.858 25.9251 49.835 26.9371 49.804 28.1531C49.772 29.3501 48.776 30.2991 47.565 30.2991H47.507C44.6428 30.2365 41.9147 29.0649 39.897 27.0311C38.9008 26.0365 38.1108 24.855 37.5721 23.5545C37.0335 22.2539 36.7568 20.8598 36.758 19.4521V13.0081H35.804C34.567 13.0081 33.564 12.0221 33.564 10.8051C33.564 9.58909 34.567 8.60209 35.804 8.60209H36.758V2.20009C36.758 0.983094 37.761 -0.00290625 38.998 -0.00290625V-0.00390625ZM16.005 13.0081H9.48302V19.4521C9.48302 23.0061 12.409 25.8971 16.005 25.8971C19.601 25.8971 22.527 23.0061 22.527 19.4521C22.527 15.8991 19.601 13.0081 16.005 13.0081Z"
        fill="#487D9D"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M32.88 5.16117C32.88 6.51917 31.764 7.62117 30.387 7.62117C29.01 7.62117 27.894 6.51917 27.894 5.16117C27.894 3.80317 29.01 2.70117 30.387 2.70117C31.764 2.70117 32.88 3.80317 32.88 5.16117ZM51.757 32.6022L60.992 27.5102C61.0896 27.4563 61.1695 27.3754 61.2222 27.2773C61.2749 27.1791 61.2982 27.0678 61.2892 26.9567C61.2802 26.8457 61.2394 26.7396 61.1716 26.6511C61.1038 26.5627 61.012 26.4957 60.907 26.4582L50.97 22.8812C50.8849 22.8505 50.7918 22.8504 50.7066 22.8809C50.6214 22.9113 50.5494 22.9705 50.503 23.0482C50.4727 23.1016 50.4554 23.1614 50.4527 23.2227C50.4499 23.2841 50.4617 23.3452 50.487 23.4012L51.918 26.6952C51.9694 26.8121 52.0527 26.9123 52.1582 26.9842C52.2638 27.0562 52.3874 27.097 52.515 27.1022L56.597 27.2792C56.6093 27.2796 56.6209 27.2846 56.6298 27.293C56.6386 27.3015 56.6441 27.313 56.645 27.3252C56.6458 27.3372 56.6421 27.3491 56.6348 27.3586C56.6274 27.3682 56.6169 27.3748 56.605 27.3772L52.603 28.1932C52.4779 28.2181 52.3623 28.2779 52.2696 28.3657C52.1769 28.4534 52.1108 28.5656 52.079 28.6892L51.196 32.1652C51.18 32.2244 51.1781 32.2867 51.1906 32.3468C51.2031 32.4069 51.2296 32.4632 51.268 32.5112C51.3262 32.5809 51.4069 32.6281 51.4962 32.6447C51.5854 32.6614 51.6777 32.6463 51.757 32.6022Z"
        fill="#D32733"
      />
    </g>
    <defs>
      <clipPath id="clip0_9_2">
        <rect width="62" height="33" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'BitLogo',
}
</script>
