import BitLogo from '@/components/PaymentMethodsIcons/BitLogo'
import { mdiCreditCard } from '@mdi/js'

export default function usePaymentMethods() {
  const paymentMethods = {
    CREDIT_CARD: 'credit-card',
    BIT: 'bit',
  }

  const paymentMethodsOptions = [
    {
      title: 'payment_method_credit_card',
      name: paymentMethods.CREDIT_CARD,
      icon: mdiCreditCard,
    },
    {
      title: 'payment_method_bit',
      name: paymentMethods.BIT,
      icon: BitLogo,
    },
  ]

  return {
    paymentMethods,
    paymentMethodsOptions,
  }
}
